* {
  box-sizing: border-box;
}

textarea {
  resize: none;
}

body {
  user-select: none;
  -webkit-user-select: none;
  -moz-user-select: none;
}

.App {
  display: block;
  overflow-x: hidden;
  box-sizing: border-box;
  font-family: Verdana, sans-serif;
}

.App * {
  box-sizing: border-box;
  font-family: Verdana, sans-serif;
}

.App .fa,
.App .fas {
  font-family: "Font Awesome 5 Free";
}

.Header {
  width: 100%;
}

.Image_overlay img {
  width: 100%;
  position: absolute;
  z-index: -1;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type="number"] {
  -moz-appearance: textfield;
}

.Static_table {
  border: 3px solid #fff;
  border-top-width: 0;
  border-left-width: 10px;
  width: 354px;
  height: auto;
  display: inline-block;
  vertical-align: top;
}

.Static_table .Table_head {
  height: 85px;
}

.Table_head {
  width: 100%;
  background-color: #a5c8c1;
  opacity: 1;
}

.Inner_border {
  width: 100%;
  height: 100%;
  border: 1px solid black;
  position: relative;
}

.Static_holder {
  width: 258px;
  border: 1px solid black;
  height: 665px;
  display: inline-block;
  vertical-align: top;
}

.Scroll_Static_Holder {
  border: 1px solid black;
  height: 657px;
  display: inline-block;
  vertical-align: top;
  border-left: none;
}

.Static_table .Static_holder .Row_Usage {
  padding: 10px;
}

.Scroll_Static_Holder {
  width: calc(100% - 258px);
}

.Scroll_Static_Holder .Description {
  height: 25px;
  text-align: center;
  font-size: 14px;
}

.Scroll_Static_Holder .Labor_req,
.Scroll_Static_Holder .Equipment_req {
  border: 1px solid black;
  height: 262px;
  display: flex;
  text-align: center;
  align-items: center;
  border-left: none;
  border-right: none;
  font-size: 14px;
}

.Scroll_Static_Holder .Trucking_req {
  border: 1px solid black;
  height: 36px;
  display: flex;
  text-align: center;
  align-items: center;
  border-left: none;
  border-right: none;
  font-size: 13px;
}

.Equipment_req {
  margin-top: 6px;
  border-left: none;
  border-right: none;
  font-size: 14px;
}

.Trucking_req {
  margin-top: 5px;
  border-left: none;
  border-right: none;
  font-size: 14px;
}

.Scroll_Static_Holder .Grinder_req {
  border: 1px solid black;
  height: 90px;
  display: flex;
  text-align: center;
  align-items: center;
  border-left: none;
  border-right: none;
  font-size: 13px;
}

.Grinder_req {
  margin-top: 5px;
  border-left: none;
  border-right: none;
  font-size: 14px;
}

.Scroll_Static_Holder .Additional_res {
  height: 52px;
  display: flex;
  text-align: center;
  align-items: center;
  border-bottom: none;
  font-size: 10px;
}

.Scroll_table {
  border: 3px solid #fff;
  border-top-width: 0;
  width: calc(100% - 354px);
  display: inline-block;
  height: 840px;
  /* overflow-x: scroll; */
}

.Scroll_holder {
  width: 2456px;
}

.Tabble_day_column {
  width: 240px;
  margin-right: 2px;
  display: inline-block;
  vertical-align: top;
  border: 1px solid black;
}

.Scroll_table .Table_head {
  width: 100%;
  height: 85px;
}

.Day_Date {
  text-align: center;
  font-weight: bold;
  position: absolute;
  top: 24px;
  width: 100%;
}

.Day_Day {
  text-align: center;
  font-weight: bold;
  position: absolute;
  top: 42px;
  font-size: 20px;
  width: 100%;
}

.Scroll_table .Bottom_Footer {
  text-align: center;
  font-weight: bold;
  position: absolute;
  bottom: 0px;
  width: 100%;
}

.Resource {
  display: inline-block;
  width: 50%;
  text-align: left;
  padding-left: 5px;
  font-size: 13px;
}

.QTY {
  display: inline-block;
  width: 50%;
  text-align: right;
  padding-right: 5px;
  font-size: 13px;
}

.Column_title {
  width: auto;
  margin-right: 2px;
  height: 25px;
}

.Column_title input {
  width: 100%;
  height: 100%;
  background-color: #f3f3f3;
  padding-left: 5px;
  font-weight: bold;
}

.Scroll_table .Column_row {
  height: 26px;
  width: 240px;
}

.Scroll_table .Column_row label {
  display: flex;
  justify-content: space-between;
}

.Scroll_table .Column_row .Dropdown {
  height: 26px;
  width: 77px;
  display: inline-block;
  border: 1px solid black;
  font-size: 13px;
  background-color: #f3f3f3;
}

.Scroll_table .Column_row .Selected_option {
  height: 26px;
  width: auto;
  padding-left: 5px;
  font-size: 13px;
  font-weight: 500;
  display: inline-block;
  vertical-align: top;
}

option {
  background-color: #fff;
}

.saveButton {
  position: absolute;
  top: 5px;
  left: 500px;
}

.Scroll_table .Column_row input {
  height: 26px;
  width: 28px;
  display: inline-block;
  border: 1px solid black;
  font-size: 13px;
  text-align: center;
  background-color: #f3f3f3;
}

.Scroll_table .Eq_req {
  margin-top: 16px;
}

.Ad_res input {
  width: 100%;
  height: 100%;
  border: 2px solid grey;
  background-color: #f3f3f3;
  text-align: center;
}

.Ad_res textarea {
  width: 100%;
  height: 100%;
  background-color: #f3f3f3;
  text-align: center;
  border: 2px solid grey;
}

.tab-list {
  border-bottom: 1px solid #ccc;
  padding-left: 5px;
  margin-top: 0;
}

.tab-list-item {
  display: inline-block;
  list-style: none;
  background-color: lightgrey;
  margin-bottom: -1px;
  padding: 0.5rem 0.75rem;
}

.tab-list-item:hover {
  cursor: pointer;
}

select:hover {
  cursor: pointer;
}

option:hover {
  cursor: pointer;
}

.tab-list-active {
  background-color: white;
  border: solid #ccc;
  border-width: 1px 1px 0 1px;
}

.Header {
  display: flex;
  justify-content: space-between;
}

.Header .col1 {
  padding: 5px;
  display: flex;
  flex-direction: column;
}

.Header .col1 button.btn.btn-primary {
  width: 100px;
  margin-bottom: 10px;
}

.Header .col1 button {
  width: 150px;
  margin-bottom: 10px;
}

.Header .col1 .Main_box {
  border: 1px solid black;
  padding: 5px 10px;
  display: inline-block;
  vertical-align: top;
  height: 95px;
  width: 500px;
  margin-bottom: 10px;
  margin-left: 6px;
}

.Header .col1 .Main_row {
  display: flex;
  margin: 2px 0px;
}

.Header .col1 .label {
  width: 150px;
  height: 25px;
  font-weight: bold;
  font-size: 14px;
}

.Header .col1 .field {
  width: 300px;
  height: 25px;
  padding-left: 5px;
}

.Header .col1 .inactive {
  color: #7b7a7a;
}

.Header .Button_holder {
  display: inline-block;
  vertical-align: top;
  width: 130px;
  margin-left: 20px;
}

.Header .Button_holder .button {
  vertical-align: top;
  border: 1px solid black;
  width: 100%;
  text-align: center;
  height: 25px;
}

.button:hover {
  cursor: pointer;
}

.Header .Button_holder .flex_fix {
  display: flex;
  height: 110px;
  flex-direction: column;
  justify-content: space-between;
}

.Header .col2 {
  display: inherit;
  font-size: 26px;
  text-align: center;
  font-weight: 500;
  padding-top: 10px;
  text-transform: uppercase;
  justify-content: center;
  align-items: center;
}

.Header .col3 {
  width: 300px;
  /* display: inline-block; */
  padding: 10px 5px;
}

.Header .col3 img {
  width: 100%;
}

.Header .col3 .version {
  font-size: 18px;
  color: #073f2c;
  font-weight: 600;
  text-align: right;
  padding-right: 30px;
}

.Summary_table {
  width: 1900px;
}

.Summary_table .Header {
  display: block;
}

.head1,
.head2 {
  display: block;
  width: 100%;
  background-color: #a5c8c1;
  margin-bottom: 5px;
}

.head1>div {
  display: inline-block;
}

.head2>div {
  display: inline-block;
}

.index {
  width: 600px;
  display: inline-block;
}

.Summary_table .column {
  width: 115px;
  display: inline-block;
  text-align: center;
  padding: 2px;
}

.column_desc {
  width: 250px;
  display: inline-block;
}

.Summary_table .table_content .row {
  margin-bottom: 5px;
}

.Summary_table .table_content .row:nth-child(2n) {
  background-color: lightgray;
}

.modal {
  display: block;
  position: absolute;
  z-index: 999;
  width: 1000px;
  background-color: white;
  border: 2px solid black;
  padding: 30px;
  top: 30vh;
  left: calc(50vw - 500px);
}

.First_select {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: 50% 50%;
  position: relative;
}

.First_select:after {
  content: " ";
  position: absolute;
  background-color: #fff;
  opacity: 0.2;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.Select_holder {
  display: flex;
  width: 100%;
  max-width: 400px;
  padding: 1rem 2rem;
  flex-direction: column;
  z-index: 1;
  background-color: #fff;
}

.Select_holder .Main_row {
  margin-bottom: 10px;
}

.Select_holder .Main_row input {
  width: 100%;
  height: 20px;
}

.logout {
	background-color: #f57b20;
	border: 2px solid #f57b20;
  color: #fff;
  box-shadow: none;
  font-size: 1rem;
  padding: 0.5rem 1rem;
  border-radius: 5px;
  transition: 0.3s;
  cursor: pointer;
}

.logout:hover {
  background-color: #fff;
  color: #000;
  border: 2px solid #f57b20;
  transition: 0.3s;
}

.submit {
  background-color: #073f2d;
  border: 2px solid #073f2d;
  color: #fff;
  box-shadow: none;
  font-size: 1rem;
  padding: 0.5rem 1rem;
  border-radius: 5px;
  transition: 0.3s;
  cursor: pointer;
}

.submit:hover {
  background-color: #fff;
  color: #000;
  border: 2px solid #073f2d;
  transition: 0.3s;
}

.submit:disabled {
  background-color: #d8d8d8;
  border: 2px solid #d8d8d8;
  color: #000;
  cursor: not-allowed;
}

.wrap_holder {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.wrap_holder img {
  padding-bottom: 2rem;
  z-index: 2;
}

.Main_row .label {
  padding-bottom: 5px;
}

.Main_row .label .label_date {
  font-size: 1rem;
  font-weight: 400;
  font-family: Arial;
}

.First_select .Select_Start_Date {
  width: 100%;
  background-color: #003f2c;
  border: 2px solid #073f2d;
  color: #fff;
  padding: 0.5rem;
  cursor: pointer;
  text-align: center;
  border-radius: 5px;
  margin-top: 10px;
}

.First_select .Select_Start_Date:hover {
  background-color: #fff;
  color: #000;
  border: 2px solid #073f2d;
  transition: 0.3s;
}

.error {
  color: red;
  text-align: center;
}

.modal {
  padding: 0;
  border: none;
  display: flex;
  width: 100%;
  left: 0;
  justify-content: center;
  background-color: transparent;
}

.modal:focus {
  outline: none;
}

.modal *:not(button):focus {
  border: none;
  outline: none;
}

.menu {
  z-index: 3;
  background-color: #fff;
  border-radius: 2px;
  padding-left: 0;
  margin: 0;
  position: absolute;
  list-style: none;
  box-shadow: 0px 2px 10px #999999;
}

.menu li {
  padding: 6px 35px 5px 10px;
  min-width: 160px;
  cursor: default;
  font-size: 12px;
}

.menu .menu-icons:hover {
  background: linear-gradient(to top, #555, #333);
  color: white;
  background-color: #f2f2f2;
}

.menu .menu-icons {
  display: flex;
  align-items: center;
}

.menu-icons li i {
  font-size: 14px;
  padding-right: 5px;
}

.noFocus:focus {
  outline: none;
}

@media print {
  .Column_row select {
    -webkit-appearance: none;
    appearance: none;
  }
}

.Forecast {
  font-size: 10px;
  text-align: center;
  font-weight: bold;
  display: flex;
  padding: 0.2rem;
  justify-content: space-between;
}

.Forecast_Day {
  font-size: 10px;
  text-align: center;
  font-weight: bold;
  display: flex;
  flex-flow: column;
  justify-content: space-between;
}

.Forecast_Night {
  font-size: 10px;
  text-align: center;
  font-weight: bold;
  display: flex;
  flex-flow: column;
  justify-content: space-between;
}

.Forecast_Icon{
  width: 30px;
  height: 30px;
}


.button {
  background-color: #4CAF50;
  /* Green */
  border: none;
  color: white;
  padding: 9px 7px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 14px;
  margin: 4px 2px;
  transition-duration: 0.4s;
  cursor: pointer;
  transition: 0.3s;
}

.button-save {
  transition: 0.3s;
  background-color: white;
  color: #000;
  border: 2px solid #f57b20;
  border-radius: 4px;
}

.button-save i {
  transition: 0.3s;
  color: #f57b20;
}

.button-save:hover {
  transition: 0.3s;
  background-color: #f57b20;
  color: white;
}

.button-save:hover i {
  transition: 0.3s;
  color: white;
}

.button-location {
  background-color: white;
  color: black;
  border: 2px solid #073f2d;
  border-radius: 4px;
  padding: 10.5px 7px;
  transition: 0.3s;
}

.button-location:hover {
  transition: 0.3s;
  background-color: #073f2d;
  color: white;
}

.button-crew {
  transition: 0.3s;
  background-color: white;
  color: black;
  border: 2px solid #073f2d;
  border-radius: 4px;
}

.button-crew i {
  transition: 0.3s;
  color: #073f2d;
}

.button-crew:hover {
  transition: 0.3s;
  background-color: #073f2d;
  color: white;
}

.button-crew:hover i {
  transition: 0.3s;
  color: white;
}

.button-calendar {
  transition: 0.3s;
  background-color: white;
  color: black;
  border: 2px solid #073f2d;
  border-radius: 4px;
}

.button-calendar i {
  transition: 0.3s;
  color: #073f2d;
}

.button-calendar:hover {
  transition: 0.3s;
  background-color: #073f2d;
  color: white;
}

.button-calendar:hover i {
  transition: 0.3s;
  color: white;
}

.button-calendar.disabled {
  background-color: #f3f3f3;
}

.button-reports-pav {
  transition: 0.3s;
  background-color: white;
  color: black;
  border: 2px solid #073f2d;
  border-radius: 4px;
}

.button-reports-pav i {
  transition: 0.3s;
  color: #073f2d;
}

.button-reports-pav:hover {
  transition: 0.3s;
  background-color: #073f2d;
  color: white;
}

.button-reports-pav:hover i {
  transition: 0.3s;
  color: white;
}

.button-ok {
  background-color: white;
  color: black;
  border: 2px solid #555555;
}

.button-ok:hover {
  background-color: #555555;
  color: white;
}

select-box {
  -webkit-appearance: none;
  -moz-appearance: none;
  -ms-appearance: none;
  appearance: none;
  outline: 0;
  box-shadow: none;
  border: 0 !important;
  background: #2c3e50;
  background-image: none;
}

/* Remove IE arrow */
select-box::-ms-expand {
  display: none;
}

/* Custom Select */
.select-box {
  width: 20em;
  height: 3em;
  line-height: 3;
  overflow: hidden;
  border-radius: .25em;
  border: 2px solid #073f2d;
  transition-duration: 0.4s;
}

select-box {
  flex: 1;
  padding: 0 .5em;
  color: #fff;
  cursor: pointer;
}

.select-box:hover {
  background-color: #073f2d;
  color: white;
}

.select-box option {
  color: #333;
}

/* Arrow */
.select-box::after {
  content: '\25BC';
  position: absolute;
  top: 0;
  right: 0;
  padding: 0 1em;
  background: #34495e;
  cursor: pointer;
  pointer-events: none;
  -webkit-transition: .25s all ease;
  -o-transition: .25s all ease;
  transition: .25s all ease;
}

/* Transition */
.select-box:hover::after {
  color: #f39c12;
}

/* Left header Style */
.Row {
  display: flex;
  justify-content: end;
  align-items: center;
  font-size: 14px;
  height: 5%;
}

.grinder .Row {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 14px;
  height: 30%;
}

.Row input {
  width: 56%;
  height: 100%;
  background-color: lightgray;
}

.grinder .Row input {
  width: 13%;
}

.Big_row {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 14px;
  height: 96%;
}

.Big_row textarea {
  width: 81%;
  overflow: hidden;
  height: 100%;
  text-align: center;
  background-color: #f3f3f3;
}

.Med_row {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 14px;
  height: 25%;
}

.Static_table .Location {
  display: flex;
  align-items: center;
  flex-direction: column;
}

.Static_table .Location textarea.active {
  border-radius: 2px;
  border: none;
  width: 80%;
  margin-top: 0.5rem;
  font-weight: bold;
  border: 1px solid black;
  text-align: center;
}

.Med_row textarea {
  width: 56%;
  height: 100%;
  text-align: center;
  background-color: #f3f3f3;
}

.Table_head_Weekend {
  width: 100%;
  background-color: rgb(252, 228, 214);
  opacity: 1;
}

.Header_Mega_Menu {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-left: 5px;
}

input.inactive.noFocus {
  font-size: 14px;
}

.log_out {
  text-align: center;
  text-decoration: none;
}